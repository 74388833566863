.audio-test-div {
  .ant-btn-primary,
  .ant-btn {
    border: none;
    &:focus,
    &:hover {
      border: none;
      background: rgba(255, 255, 255, 0.9);
      color: #000;
    }
  }
  .secondary-btn {
    background: transparent;
    border: solid thin #fff;
  }
  .audio-block {
    height: 15px;
    width: 4px;
    margin-right: 2px;
  }
  .active-audio-block {
    background: rgba(0, 0, 0, 0.9);
  }
  .inactive-audio-block {
    background: rgba(0, 0, 0, 0.3);
  }
  .mic-icon {
    margin-top: 3px;
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
