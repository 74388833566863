@import 'styles/_mixin.scss';

.device-test-pg {
  min-height: 100vh;
  overflow-x: hidden;
  background: rgba(28, 28, 28, 1);
  transition: all 1s ease;
  padding: 30px 118px 30px 118px;
  @include tablet {
    padding: 30px;
  }
  > section {
    @include tablet {
      margin-top: 40px;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  p {
    margin: 0;
  }

  .font-ibm {
    font-family: 'IBM Plex Sans', 'Messina Sans';
  }

  .heading {
    letter-spacing: -2px;
    line-height: 71px;
    @include tablet {
      font-size: 38px;
      width: 90vw;
      line-height: 51px;
      margin: 10px 0;
    }
  }

  .subheading {
    width: 440px;
    line-height: 28px;
    @include tablet {
      width: 90vw;
    }
  }
  ol {
    @include tablet {
      margin: 40px 0px 40px 0px;
    }
  }

  .list-item-heading {
    letter-spacing: -0.2px;
    line-height: 37px;
  }

  .list-item-subheading {
    line-height: 24px;
    margin-left: -15px;
  }

  .start-test-container {
    width: 620px;
    @include tablet {
      width: 85vw;
    }
    .final-message-txt {
      @include tablet {
        top: 0;
        font-size: 30px;
      }
    }
  }

  .start-test-div {
    width: 494px;
    height: 380px;
    background: rgba(47, 47, 47, 1);

    :nth-child(1) {
      letter-spacing: -0.2px;
    }

    :nth-child(2) {
      width: 104px;
      height: 104px;
      background: rgba(74, 74, 74, 1);
      border-radius: 50%;
    }

    :nth-child(3) {
      line-height: 24px;
      width: 348px;
      margin-bottom: 20px;
    }
  }

  .testing-div {
    width: 620px;
    @include tablet {
      width: 85vw;
      margin-top: 40px;
    }

    .heading,
    .subheading {
      letter-spacing: 0.5px;
      line-height: 29px;
      @include tablet {
        font-size: 16px;
      }
    }

    .video-container {
      width: 494px;
      height: 380px;
      @include tablet {
        width: 90vw;
        height: 230px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          rgba(0, 0, 0, 0.95),
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.95)
        );
        border-radius: 20px;
      }

      video {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    #recorded {
      width: 450px;
      height: 340px;
      @include tablet {
        width: 85vw;
        height: 230px;
        margin-top: 20px;
      }
    }

    .recording-info-container {
      width: 494px;
      @include tablet {
        width: 90vw;
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
      .recording-icon {
        background: rgba(255, 76, 76, 1);
        height: 10px;
        width: 10px;
        border-radius: 50%;
        @include tablet {
          width: 5px;
          height: 5px;
        }
      }

      .progress-bar-item {
        height: 4px;
        width: 20px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 1);
        transition: all 0.5s ease;
        @include tablet {
          width: 25px;
        }
      }

      .disabled-btn {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    .recording-review-container {
      width: 494px;
      @include tablet {
        width: 90vw;
      }
      .review-heading {
        @include tablet {
          font-size: 18px;
        }
      }

      .review-ques {
        @include tablet {
          font-size: 16px;
        }
      }

      .review-divider-line {
        height: 1px;
        width: 100%;
        opacity: 0.3;
        background: rgba(217, 217, 217, 1);
        margin-top: 4px;
      }
    }

    .error-msg-container {
      width: 613px;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(224, 32, 32, 1);
      @include tablet {
        width: 90vw;
        flex-direction: column;
      }
      .error-txt {
        line-height: 19px;
      }
      button {
        @include tablet {
          margin-top: 20px;
        }
      }
    }
  }
  .testing-up {
    top: -60px;
  }
  .testing-review-up {
    top: -60px;
  }
  .dropdowns-container {
    @include tablet {
      flex-direction: column;
    }
    .video-dropdown-container {
      @include tablet {
        margin-bottom: 10px;
      }
      .video-select {
        @include tablet {
          margin-right: 0px;
        }
      }
    }
  }
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 0.18s ease-in forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
