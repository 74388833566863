@import 'styles/_mixin.scss';

.signup-form {
  // display: flex;
  // flex-wrap: wrap;
  .ant-form-item {
    margin: 0;

    input {
      margin: 0;
      border-radius: 80px;
      padding: 15px 30px !important;
      outline: none;
      &::placeholder {
        letter-spacing: 0.2px !important;
      }
    }
  }
}

.signup-continue-btn {
  @include tablet {
    width: 90%;
    bottom:30px;
  }
}

.mobile-continue-btn {
  width: 100vw;
  position: fixed;
  bottom: 10px;
  left: 0;
  display: flex;
  justify-content: center;
  button {
    width: 150px;
  }
}

.error {
  background: rgba(255,255,255, 0.1);
  border-style: solid;
  border-width: thin;
  border-color: rgba(231,70,70,1);
  border-radius: 12px;
  width: max-content;

  @include tablet {
    max-width: 90vw; 
  }

  img {
    @include tablet {
      width: 15px;
      height: auto;
    }
  }
}