// cursor
.cursor-alias {
  cursor: alias;
}
.cursor-all-scroll {
  cursor: all-scroll;
}
.cursor-auto {
  cursor: auto;
}
.cursor-cell {
  cursor: cell;
}
.cursor-context-menu {
  cursor: context-menu;
}
.cursor-col-resize {
  cursor: col-resize;
}
.cursor-copy {
  cursor: copy;
}
.cursor-crosshair {
  cursor: crosshair;
}
.cursor-default {
  cursor: default;
}
.cursor-e-resize {
  cursor: e-resize;
}
.cursor-ew-resize {
  cursor: ew-resize;
}
.cursor-grab {
  cursor: grab;
}
.cursor-grabbing {
  cursor: grabbing;
}
.cursor-help {
  cursor: help;
}
.cursor-move {
  cursor: move;
}
.cursor-n-resize {
  cursor: n-resize;
}
.cursor-ne-resize {
  cursor: ne-resize;
}
.cursor-nesw-resize {
  cursor: nesw-resize;
}
.cursor-ns-resize {
  cursor: ns-resize;
}
.cursor-nw-resize {
  cursor: nw-resize;
}
.cursor-nwse-resize {
  cursor: nwse-resize;
}
.cursor-no-drop {
  cursor: no-drop;
}
.cursor-none {
  cursor: none;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-progress {
  cursor: progress;
}
.cursor-row-resize {
  cursor: row-resize;
}
.cursor-s-resize {
  cursor: s-resize;
}
.cursor-se-resize {
  cursor: se-resize;
}
.cursor-sw-resize {
  cursor: sw-resize;
}
.cursor-text {
  cursor: text;
}
.cursor-wait {
  cursor: wait;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.cursor-zoom-out {
  cursor: zoom-out;
}

//  flex classes
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}
.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

// grid
.grid {
  display: grid;
}
.grid-cols-none {
  grid-template-columns: none;
}
.grid-cols-auto {
  grid-template-columns: auto;
}
.grid-rows-none {
  grid-template-rows: none;
}
.grid-rows-auto {
  grid-template-rows: auto;
}
@for $i from 1 through 12 {
  .grid-gap-#{$i} {
    grid-gap: 1px * $i;
  }
}

.color {
  &-primary {
    color: $primary-text;
  }
  &-secondary {
    color: $secondary-text;
  }
  &-alt {
    color: $alt-text;
  }
  &-danger {
    color: $danger;
  }
}
.bg-color {
  &-primary {
    background-color: $primary-bg;
  }
  &-secondary {
    background-color: $secondary-bg;
  }
}

.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.normal-case {
  text-transform: none;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
}
.overflow-clip {
  text-overflow: clip;
}
.overflowX-hidden {
  overflow-x: hidden;
}
.overflowY-hidden {
  overflow-y: hidden;
}

// grid-cols
@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }
}

// grid-rows
@for $i from 1 through 12 {
  .grid-rows-#{$i} {
    grid-template-rows: repeat($i, minmax(0, 1fr));
  }
}

// font-sizes
@for $i from 1 through 500 {
  .size-#{$i} {
    font-size: 1px * $i;
  }
}

// text-opacity
@for $i from 0 through 100 {
  .opacity-#{$i} {
    opacity: ($i) / 100;
  }
}

// font-weight
@for $i from 100 through 900 {
  .weight-#{$i} {
    font-weight: $i;
  }
}

// width
@for $i from 1 through 500 {
  .width-#{$i} {
    width: 1px * $i;
  }
  .max-width-#{$i} {
    max-width: 1px * $i;
  }
}

// padding
@for $i from 0 through 60 {
  .p-#{$i} {
    padding: 1px * $i;
  }
}

// padding-bottom
@for $i from 0 through 60 {
  .pb-#{$i} {
    padding-bottom: 1px * $i;
  }
}

// padding-top
@for $i from 0 through 60 {
  .pt-#{$i} {
    padding-top: 1px * $i;
  }
}

// padding-left
@for $i from 0 through 200 {
  .pl-#{$i} {
    padding-left: 1px * $i;
  }
}

// padding-right
@for $i from 0 through 200 {
  .pr-#{$i} {
    padding-right: 1px * $i;
  }
}

// margin
@for $i from 0 through 40 {
  .m-#{$i} {
    margin: 1px * $i;
  }
}

// margin-top
@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: 1px * $i;
  }
}

// margin-bottom
@for $i from 0 through 90 {
  .mb-#{$i} {
    margin-bottom: 1px * $i;
  }
}

//margin-left
@for $i from 0 through 20 {
  .ml-#{$i} {
    margin-left: 1px * $i;
  }
}

// margin-right
@for $i from 0 through 20 {
  .mr-#{$i} {
    margin-right: 1px * $i;
  }
}

// border
@for $i from 0 through 20 {
  .br-#{$i} {
    border-radius: 1px * $i;
  }
}

// border-top-left
@for $i from 0 through 20 {
  .btl-#{$i} {
    border-top-left-radius: 1px * $i;
  }
}

// border-top-right
@for $i from 0 through 20 {
  .btr-#{$i} {
    border-top-right-radius: 1px * $i;
  }
}

// border-bottom-left
@for $i from 0 through 20 {
  .bbl-#{$i} {
    border-bottom-left-radius: 1px * $i;
  }
}

// border-bottom-right
@for $i from 0 through 20 {
  .bbr-#{$i} {
    border-bottom-right-radius: 1px * $i;
  }
}
//position
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}

//position-align

// top
@for $i from 0 through 90 {
  .top-#{$i} {
    top: 1px * $i;
  }
}
// bottom
@for $i from 0 through 90 {
  .bottom-#{$i} {
    bottom: 1px * $i;
  }
}
// left
@for $i from 0 through 90 {
  .left-#{$i} {
    left: 1px * $i;
  }
}
// right
@for $i from 0 through 90 {
  .right-#{$i} {
    right: 1px * $i;
  }
}

// alignments
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.ant-form-item-has-error .ant-input {
  border: 1px solid red;
}
.flex-1 {
  flex: 1;
}
// display
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.m-auto {
  margin: 0 auto;
}
.w-full {
  width: 100%;
}
@for $i from 1 through 100 {
  .width-p-#{$i} {
    width: 1% * $i;
  }
}
@for $i from 1 through 100 {
  .height-p-#{$i} {
    height: 1% * $i;
  }
}
.h-full {
  height: 100%;
}
.h-full-fix {
  height: 100vh;
}
.h-full-page {
  min-height: 100vh;
}

/* fonts */
.font-bold {
  font-family: 'Messina Sans', Fallback, sans-serif;
  font-weight: 500;
}
.font-medium {
  font-family: 'Messina Sans', Fallback, sans-serif;
}
.font-italic {
  font-family: 'Messian Sans', Fallback, sans-serif;
  font-style: italic;
}
.font-light {
  font-family: 'Messina Sans', Fallback, sans-serif;
  font-weight: 300;
}
.screen-only {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  width: 100vw;
  img {
    width: 50%;
  }
}

.screen-only-message {
  font-size: 3em;
  margin-top: 20px;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 20px 20px;
  -webkit-backdrop-filter: blur(18.4px);
  backdrop-filter: blur(18.4px);
  box-shadow: 0 9px 24px 0 rgba(0, 0, 0, 0.18);
  background-color: rgba(100, 96, 96, 0.33);
  img {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }
}

@media (min-width: 320px) {
  .screen-only-message {
    font-size: 0.8em;
  }
}

@media (min-width: 375px) {
  .screen-only-message {
    font-size: 1em;
  }
}

@media (min-width: 414px) {
  .screen-only-message {
    font-size: 1.2em;
  }
}

@keyframes fadeInScale-6 {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  20% {
    opacity: 0.2;
    transform: scale(0.9);
  }
  40% {
    opacity: 0.4;
    transform: scale(1);
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
