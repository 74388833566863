@import 'styles/_mixin.scss';

.hero-section {
  background-color: #000000;

  &>div {
    flex: 1;

    &:first-of-type {
      height: 100%;
      padding: 51px 98px 0 74px;

      @include tablet {
        padding: 20px;
      }

      display: flex;
      flex-direction: column;

      .company-logo {
        font-family: 'Messian Sans';

        img {
          height: auto;
          width: 150px;

          @include tablet {
            width: 80px;
          }
        }

        p {
          letter-spacing: 0.3px;
          font-weight: 300;
          white-space: nowrap;
        }

        span {
          font-weight: 600;
        }
      }

      .logo {
        font-family: 'Messian Sans', Fallback, sans-serif;
        font-weight: 400;
        font-size: 34px;
        color: white;
        margin: 0;

        @include tablet {
          font-size: 20px;
        }
      }

      .greetings-form-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 60px;
        justify-content: center;

        @include tablet {
          gap: 15px;
        }

        input {
          border-radius: 80px;
          padding: 20px 30px;
          font-size: 16px;

          // &:focus {
          //   border-color: white;
          // }

          &::placeholder {
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: -0.81448px;
            color: #ffffff;
            opacity: 0.5;
          }
        }

        button {
          color: #000;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          background: #ffffff;
          border-radius: 80px;
          padding: 14px 56px;
        }
      }
    }

    &:last-of-type {
      align-self: flex-end;

      @include tablet {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
      }
    }
  }

  .greetings {
    display: flex;
    flex-direction: column;
    gap: 11px;

    .text-center {
      @include tablet {
        text-align: center;
      }
    }

    .heading-60 {
      font-size: 60px !important;
      line-height: 66px !important;

      @include tablet {
        font-size: 36px;
        line-height: normal;
        letter-spacing: -3px;
      }
    }

    >h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 40px;
      letter-spacing: -2px;
      color: #fff;
      margin: 0;

      @at-root .godrej-style#{&} {
        @include tablet {
          font-size: 32px;
          line-height: normal;
          letter-spacing: -2px;
          // width: 80%;
        }
      }
    }

    >p {
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #ffffff;
      opacity: 0.7;
      margin: 0;
      width: 75%;

      @include tablet {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .bars-bg {
    >svg {
      width: 100%;
      height: auto;

      @at-root .godrej-style#{&} {
        @include tablet {
          display: none;
        }
      }
    }
  }
}