@import 'styles/_mixin.scss';


.end-screen-pg {
  gap: 100px;
  overflow-x: hidden;
  @include tablet {
    flex-direction: column;
    gap: 40px;
  }

.heading-emoji {
  font-size: 80px;

  @include tablet {
    font-size: 60px;
  }
}

.heading {
  font-weight: 600;
  font-size: 60px;
  line-height: 40px;
  /* identical to box height, or 115% */
  margin-top: 20px;
  text-align: center;
  letter-spacing: -0px;
  height: 46px;
  margin-bottom: 10px;
  color: #FFFFFF;

  @include tablet {
    font-size: 36px;
    width: 90%;
  }
}
.stars {
  margin-top: 30px;
  padding: 20px;
  width: 10%;
  vertical-align: middle;

  @include tablet {
    width: 40%;
  }
}
.top-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #0b75bc;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #000;
  z-index: 5;
  @include tablet {
    position: absolute;
    bottom: 30px;
    width: 40%;
    display: flex;
    align-items: center;
  }
}
.top-btn:hover {
  color: #000;
}
.sub-heading {
  font-style: normal;
  font-size: 17px;
  color: #FFFFFF;
  opacity: 0.7;
  @include tablet {
    text-align: center;
  }
}

.down-heading {
  font-style: normal;
font-size: 18px;
text-align: center;
letter-spacing: -1px;

color: #FFFFFF;
}

.message-container {
  width: 40vw;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  @include tablet {
    width: 90vw;
    align-items: center !important;
  }
}
}

.feedback-translate {
  transform: translateX(100%);
  opacity: 0;
}