@import 'styles/_mixin.scss';
@import 'styles/_variables.scss';

.rules-container {
  .desktop-start-button {
    >button {
      border-radius: 25px;
      width: 150px;
    }
  }

  .sticky-section {
    position: sticky;
    top: 0;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .mobile-start-button {
    position: fixed;
    bottom: 0px;
    left: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 40vh;
    background: linear-gradient(180deg,
        rgba(28, 28, 28, 0) 39.58%,
        #362C2D 100%);

    &>button {
      border-radius: 25px;
      width: 150px;
    }
  }

  @include tablet {
    flex-direction: column;
    padding: 0 20px 50px !important;

    &>div {
      width: 100%;
      height: initial;
      padding: 0;
      margin-top: 20px;

      .desktop-start-button {
        display: none;
      }

      .greeting {
        font-size: 24px;
        margin-bottom: 10px;
      }
    }
  }

  .line-height-40 {
    line-height: 40px;
    letter-spacing: -2px;
  }

  .note-div {
    border: solid thin rgba(255, 175, 81, 1);

    h3 {
      color: rgba(255, 175, 81, 1);
    }
  }

  .proctoring-notes-container,
  .position-instructions-container {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--primary-text);
    }

    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 18px;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    p {
      font-size: 16px;
    }

    table {
      border: solid thin #ffaf51;
      border-collapse: collapse;
      color: var(--primary-text);

      @include tablet {
        width: 100% !important;
      }

      tbody {
        tr {
          &:nth-child(1) {
            background: #7c7c7c;

            td {
              font-weight: 700;
            }
          }

          td {
            border: none;
            border-bottom: solid thin #ffaf51;
            padding-left: 10px;
          }
        }
      }
    }

    ol {
      margin-left: -1rem;

      li {
        list-style: decimal;
        color: var(--primary-text);

        p,
        span {
          margin-left: -1.5rem;
          font-weight: 300;
        }

        ul {
          margin-left: 0px;

          li {
            list-style: circle;
            margin-left: -1.5rem;
            font-weight: 300;
          }
        }
      }
    }

    ul {
      margin-left: -1rem;

      li {
        list-style: disc;
        color: var(--primary-text);

        ul {
          li {
            list-style: circle;
            font-weight: 300;
          }
        }

        p,
        span {
          margin-left: -1rem;
          font-weight: 300;
        }
      }
    }
  }

  .proctoring-notes-container {

    .heading {
      color: rgba(255, 175, 81, 1);
      opacity: 1;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      opacity: 0.7;
      font-weight: 300;
    }

    table {
      font-size: 16px;
      opacity: 0.7;
    }

    ol {

      li {
        font-size: 16px;
        opacity: 0.7;

        p,
        span {
          font-size: 12px;
          opacity: .7;
        }

        ul {

          li {
            font-size: 12px;
            opacity: .7;
          }
        }
      }
    }

    ul {

      li {
        font-size: 16px;
        opacity: 0.7;

        ul {
          li {
            opacity: .7;
            font-size: 12px;
          }
        }

        p,
        span {
          opacity: .7;
          font-size: 12px;
        }
      }
    }
  }

  .position-instructions-container {
    p {
      opacity: 0.7;
    }

    table {
      font-size: 18px;
    }

    ol {

      li {
        font-size: 20px;

        p,
        span {
          font-size: 14px;
          opacity: .7;
          margin-bottom: 20px;
        }

        ul {

          li {
            font-size: 14px;
            opacity: .7;
          }
        }
      }
    }

    ul {

      li {
        font-size: 20px;

        ul {
          li {
            opacity: .7;
            margin-bottom: 20px;
            font-size: 14px;
          }
        }

        p,
        span {
          opacity: .7;
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.rules-list {
  list-style-type: none;

  &>li:not(:last-of-type) {
    margin-bottom: 30px;

    @include tablet {
      margin-bottom: 30px;
    }
  }

  @include tablet {
    &>li>span:first-of-type {
      font-size: 18px;
    }
  }
}

.rules-ul-list {
  list-style-type: none;
}

.game-start-btn {
  width: 200px;
}

.ready-btn {
  width: 170px;

  >span {
    font-weight: 600;
  }
}

.fullscreen-icon {
  @include tablet {
    width: 60px;
  }
}

.logo {
  font-family: 'Messian Sans';

  img {
    height: 54px;
    width: auto;
  }

  p {
    letter-spacing: 0.3px;
    font-weight: 300;
    white-space: nowrap;
  }

  span {
    font-weight: 600;
  }
}

.mobile-company-logo {
  img {
    height: auto;
    width: 100px;
  }

  @extend .logo;
  position: relative;
  top: 20px;
}

.company-logo {
  img {
    height: auto;
    width: 100px;
  }

  @extend .logo;
  position: relative;
  bottom: 30px;

  @include tablet {
    bottom: unset;
  }

  p {
    left: unset;
  }

  &.proctored {
    bottom: unset;
  }
}

.vertical-line {
  border-left: solid thin #ffffff;
  height: 100%;
}

.unberry-logo {
  max-width: 150px;
  height: auto;

  @include tablet {
    margin-bottom: 20px !important;
  }
}

.height-100 {
  height: 100%;
}

.left-section {
  justify-content: flex-start;
}