@import 'styles/_mixin.scss';

.home-container {
  height: 100%;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  @include tablet {
    .greetings {
      font-size: 24px;
    }

    form {
      width: 100%;
    }

    .form {
      flex-direction: column;
      margin-top: 0px;
      width: 100%;

      input {
        margin: 0 !important;
        @include tablet {
          width: 300px;
          border-radius: 25px;
        }
      }
      .mobile-continue-btn {
        width: 100vw;
        position: fixed;
        bottom: 10px;
        left: 0;
        display: flex;
        justify-content: center;
        button {
          padding: 10px 15px;
          width: 150px;
        }
      }
      button {
        @include tablet {
          // position: absolute;
          // bottom: 20px;
          // width: 150px;
        }
      }
      .ant-row {
        width: 100%;
      }
      .ant-row:last-of-type {
        margin: 10px 0 0 !important;
      }
    }
  }

  .email-input {
    width: 70%;
  }
}

.loading {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 90;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.backdrop {
  filter: blur(135.914px);
}

.after-load {
  margin: 0 auto;
  & > span {
    text-align: center;
    // font-style: italic;
  }
}

.user-details-from {
  width: 100%;
}

.content {
  width: 60%;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.225px;

  @include tablet {
    width: 100%;
    line-height: 24px;
    font-size: 16px !important;
  }
}

.sub-content {
  width: 500px;

  @include tablet {
    width: 100%;
    line-height: 24px;
    font-size: 16px !important;
  }
}

.opacity {
  opacity: 0.7;
}

.continue {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #000000;
}

.verify {
  line-height: 20px;
  letter-spacing: -0.225px;
  mix-blend-mode: normal;
  opacity: 0.7;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.enabled {
  cursor: pointer;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.otp-model {
  @include tablet {
    & > div {
      margin: 0;
      width: 100% !important;
      max-width: none;
      padding: 0;
      top: unset !important;
      bottom: 0% !important;
      position: absolute;
    }
    .ant-modal-body {
      padding: 40px 20px !important;
      height: fit-content !important;

      .verify-otp {
        .model-heading {
          font-size: 24px;
        }
      }
    }
  }
}

.mobile-company-logo {
  position: relative;
  top: 20px;
  font-family: 'Messian Sans';
  img {
    height: auto;
    width: 100px;
  }
  p {
    letter-spacing: 0.3px;
    font-weight: 300;
    white-space: nowrap;
  }
  span {
    font-weight: 600;
  }
}

.error {
  background: rgba(255, 255, 255, 0.1);
  border-style: solid;
  border-width: thin;
  border-color: rgba(231, 70, 70, 1);
  border-radius: 12px;
  width: max-content;

  @include tablet {
    max-width: 90vw;
  }

  img {
    @include tablet {
      width: 15px;
      height: auto;
    }
  }
}
