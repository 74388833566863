@import 'styles/_mixin.scss';

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;

    &:hover {
        .tooltip_text {
            visibility: visible;
            opacity: 1;
        }
    }

    .tooltip_text {
        visibility: hidden;
        width: 220px;
        background-color: rgb(63, 63, 63);
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 110%;
        left: 50%;
        margin-left: -118px;

        @include tablet {
            margin-left: -200px;
        }

        opacity: 0;
        transition: opacity 0.2s;

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: 0px;
            border-width: 8px;
            border-style: solid;
            border-color: rgb(63, 63, 63) transparent transparent transparent;

            @include tablet {
                margin-left: 84px;
            }
        }
    }
}