// src/StyledTable.scss
@import 'styles/_mixin.scss';

.assessment-section {
	margin-top: 10px;
	margin-bottom: 10px;

	.table-container {
		display: flex;
		align-items: center;
	}

	.styled-table {
		width: 80%;
		border-collapse: collapse;
		margin: 0 0;
		font-size: 18px;
		text-align: left;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
		border: solid thin #ffaf51;

		@include tablet {
			width: 100%;
		}

		thead {
			tr {
				background-color: #7c7c7c;
				color: #ffffff;
				text-align: left;
			}
		}

		th,
		td {
			padding: 6px 15px;
			white-space: nowrap;
			@include tablet {
				white-space: unset;
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid #ffaf51;
			}
		}
	}
}