@import 'styles/_mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');

.global-timer {
  background-color: rgba(255, 255, 255, 0.05);
  width: 130px;
  height: 50px;
  order: -1;
}
.global-header-gradient {
  right: -200px;
  width: 800px;
  height: 250px;
  border-radius: 800px;
  background: #ac95cf;
  filter: blur(256px);
}
.global-header {
  position: relative;
  background: black;
  overflow: hidden;
  span {
    font-family: 'IBM Plex Sans';
  }
  .games {
    &.mobile {
      width: 70%;
    }
    &.mobile-proc {
      width: 60%;
    }
  }  
  .ant-progress-inner {
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.1);
    .ant-progress-bg {
      border-radius: 0px;
    }
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  min-height: 60px;
  padding: 5px 50px 0px 50px;

  @include tablet {
    padding: 5px 15px;
  }

  .assessment-center-cta {
    .exit-fullscreen-btn {
      font-weight: 500;
      z-index: 1;
      > img {
        width: 30px;
      }
    }
  }
}

.ant-tooltip {
  position: absolute;
  top: 54px !important;
  max-width: 152px !important;
  left: unset !important;
  right: 28px !important;
}

.newhint {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  // padding: 8px 10px;
  font-size: 16px;
  color: #fff;
}

.hint-wrapper {
  position: absolute;
  width: 280px;
  right: 55px;
  height: 30px;
  margin-top: 95px;
  z-index: 100;

  .x {
    line-height: 16px;
    padding-bottom: 2px;
    position: absolute;
    background: #313947;
    color: white;
    border: 1px solid #d2e5ff;
    border-radius: 50%;
    top: -10px;
    cursor: pointer;
    right: -10px;
  }

  .hint-box {
    align-items: center;
    justify-content: center;
    border: 1px solid #d2e5ff;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px;
    text-align: left;
    font-style: normal;
    color: #ffffff;
  }
}
