@import 'styles/_mixin.scss';
.form-pg {
  min-height: 100vh;
  overflow-x: hidden;
  .form-container {
    * {
      font-family: 'IBM Plex Sans';
    }
    .form-heading {
      letter-spacing: -2px;
    }
    .category-heading {
      line-height: 18.2px;
      letter-spacing: 1px;
    }
    .grid {
      @include tablet {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .file-upload {
      border: dashed thin rgba(255, 255, 255, 0.8);
    }
    .file-error {
      border-color: #ff4d4f;
    }
    .submit-btn-container {
      @include tablet {
        display: flex;
        justify-content: center;
        button {
          width: 60%;
        }
      }
    }
    .ant-form-item-has-error {
      .ant-picker-input {
        input {
          border: none;
        }
      }
    }
    .ant-form-item {
      margin: 0;
      input {
        width: 360px;
        margin: 0;
        border-radius: 80px;
        padding: 15px 30px !important;
        outline: none;
        &::placeholder {
          letter-spacing: 0.2px !important;
        }
      }
      &.godrej-custom {
        input {
          width: 360px !important;
          @include tablet {
            width: 320px !important;
          }
        }
      }
      .ant-picker {
        background-color: rgba(255, 255, 255, 0.12) !important;
        padding: 0px 10px 0px 0px !important;
        outline: none !important;
        border-radius: 80px !important;
        border: none !important;
        color: #fff !important;
        font-size: 16px !important;
        width: 320px !important;
        margin-bottom: 0px !important;
        input {
          max-width: 260px;
        }
        .ant-picker-input > input:placeholder-shown {
          opacity: 0.4;
        }
        .ant-picker-clear {
          right: 30px;
        }
      }
      .ant-picker-status-error {
        border: solid thin #ff4d4f !important;
      }
      textarea.ant-input {
        border-radius: 10px;
        resize: none;
      }
      .flag-phone-ext {
        top: 4px;
        left: 10px;
      }
      .phone-input .ant-form-item .ant-input {
        padding: 15px 0px 15px 110px !important;
      }
    }
    .ant-input:focus {
      border: 0px;
    }
    .ant-input:active {
      border: 0px;
    }
    #candidate-form {
      .options-input {
        grid-column: 1/-1;
      }
      
    }
    #candidate-form_gender {
      @include tablet {
        align-items: flex-start;
      }
      .ant-radio-group {
        @include tablet {
          width: 60%;
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    .ant-radio-group {
      @include tablet {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
    }
    .ant-radio-button-wrapper {
      border-radius: 20px;
      border: none;
      &::before {
        width: 0px;
      }
      &:not(&:last-child) {
        margin-right: 10px;
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #000;
      background: #fff;
      border: solid thin #fff;
      &:hover {
        color: #000;
        background: #fff;
        border: solid thin #fff;
      }
    }
    .ant-radio-button-wrapper {
      color: #fff;
      background: rgba(255, 255, 255, 0.06);
      border: solid thin rgba(255, 255, 255, 0.2);
      &:hover {
        color: #fff;
        background: rgba(255, 255, 255, 0.06);
        border: solid thin rgba(255, 255, 255, 0.2);
      }
    }
    .phone-input {
      .ant-select.ant-select-in-form-item {
        width: 100px !important;
      }
      .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: 2px;
        padding: 0 !important;
      }
    }
    .location-select {
      background: rgba(255, 255, 255, 0.12);
      border-radius: 40px;
      min-width: 360px;
      width: fit-content;
      max-width: 720px;

      .ant-select-selector {
        border: none;
      }
    }
    .ant-input-number {
      border-radius: 40px;
    }
    .ant-input-number-input {
      &:active {
        border: none;
      }
      &:focus {
        border: none;
      }
    }
    .ant-select-selection-placeholder {
      opacity: 0.4;
      margin-left: 20px;
      font-weight: 400;
      text-transform: capitalize;
    }
    .ant-select-selection-overflow {
      gap: 4px;
    }
    .ant-select-multiple .ant-select-selection-item {
      color: #000;
      border-radius: 20px;
      padding: 4px 10px;
      height: fit-content;
      border: none;
    }
    .ant-select-single .ant-select-selection-item {
      margin-left: 20px;
    }
    .ant-select-multiple .ant-select-selection-item-remove {
      margin-left: 4px;
      svg {
        margin-bottom: -1.5px;
      }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      padding-left: 4px !important;
    }
    .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
      background: rgba(255, 255, 255, 0.02);
      border-radius: 40px;
      border: none !important; 
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) 
    .ant-select-selector {
      border: none;
      box-shadow: none;
    }
  }
  .ant-select-arrow {
    color: white;
  }
}

.candidateform-select-popup {
  background: #3A3A3A !important;
  border-radius: 20px !important;
  padding: 0px;
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #555555 !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #4b4a4a !important;
    .ant-select-item-option-state {
      color: #ac95cf !important;
    }
  }
  .ant-select-item-option-content {
        color: #FFF;
        font-weight: 400;
        margin-left: 4px;
        letter-spacing: 0.5px;
  }
  .ant-empty-description {
    color: #fff;
  }
}