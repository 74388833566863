@import 'styles/_mixin.scss';

.feedback-container {
  max-width: 520px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  
  .ant-btn[disabled] {
    color: rgba(0, 0, 0, 1) !important;
  }

  .text-error {
    color: rgba(231, 70, 70, 1);
    animation: fadeInScale-6 0.2s linear;
    @include tablet {
      text-align: center;
    }
  }

  @include tablet {
    width: 90vw;
    align-items: center !important;
    justify-content: center;
  }
}

.steps-container {
  background: rgba(255, 255, 255, 0.06);
  @include tablet {
    padding: 10px !important;
  }
}

.tags-container {
  max-width: 470px;
  gap: 8px 2px;
  @include tablet {
    max-width: 100%;
    justify-content: center;
  }
  .disabled-tag {
    cursor: not-allowed !important;
  }
}

.feedback-category-heading {
  @include tablet {
    text-align: center;
  }
}

.thankyou-opacity {
  animation: fadeInScale-6 0.5s ease-out;
}
