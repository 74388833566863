.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.indicators {
  display: flex;
  justify-content: center;

  span {
    height: 10px;
    width: 10px;
    background-color: #a8a8a8;
    border-radius: 50%;
    margin: 0 10px !important;
    &.active {
      background-color: #ffffff;
    }
  }
}
