.game-container {
  height: 100%;
}

#game-window {
  height: calc(100% - 60px);
}

.game-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  background: '#ccc';
}

.game-loader {
  height: 100%;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(4px);
}

#game-frame {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  border: none;
}

.ant-modal.warning-modal .ant-modal-content {
  background-color: #292929;
  background: #292929;
}

.flex-gap-10 {
  gap: 10px;
}
