@import 'styles/_mixin.scss';

.not-found-container {
    @include tablet {
        padding: 0 !important;

        & > div {
            width: 100%;
            padding: 20px;
            text-align: center;
        }
    }
}