#report-window {
  height: 100%;

  #report-frame {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }
}
