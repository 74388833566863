@import './_variables.scss';
@import 'styles/_mixin.scss';

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: transparent !important;
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
}

.ant-form-item {
  margin-bottom: 15px;
}

.ant-input, .ant-input-number-input {
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.12); 
  outline: none;
  border-radius: 5px;
  border: none;
  color: #fff;
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
  &::placeholder {
    font-size: 16px;
    opacity: 0.3;
    font-family: 'CircularStd-Medium', Fallback, sans-serif;
  }
  &:active,
  &:focus {
    border: 1px solid white;
  }
}

.ant-input[disabled] {
  background-color: rgba(255, 255, 255, 0.06) !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.ant-btn {
  background-color: #fff;
  color: #000;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  height: fit-content;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  &:active,
  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: 25px;
    background: rgba(46, 46, 46, 0.4);
    backdrop-filter: blur(5px);
    mix-blend-mode: normal;
  }
}

.ant-select {
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  &:hover {
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
    border: none;
  }
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  padding: 10px;
  color: #fff;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
  border: none;
}

.ant-picker {
  padding: 12px !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
  outline: none !important;
  border-radius: 5px !important;
  border: none !important;
  color: #fff !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  font-size: 16px !important;
}
.ant-picker-input input {
  color: #ffffff;
}
.ant-picker-suffix {
  color: #fff;
}
.ant-form-item-has-error .ant-picker {
  // border-color: #ff4d4f !important;
  border: 1.5px solid red !important;
}

.ant-input-number {
  width: 100%;
  background-color: transparent !important;
  border: none;
  box-shadow: none;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap {
    width: 100%;

    input {
      height: auto;
      outline: none;
      color: #fff;
      outline: none;

      &::placeholder {
        font-size: 16px;
        opacity: 0.3;
      }
    }
  }
}

.ant-form-item-has-error {
  .ant-input-number-focused {
    box-shadow: none;
  }

  input {
    background-color: transparent;
    border: 1px solid red;

    &:focus {
      border-color: #ff7875;
    }
  }
}

.ant-steps-item-icon {
  margin: 0px !important;
  @include tablet {
    width: 22px !important;
    height: 22px !important;
  }
  > .ant-steps-icon {
    display: inline-block !important;
    border-radius: 50% !important;
    @include tablet {
      width: 22px !important;
      height: 22px !important;
    }
    > p {
      @include tablet {
        margin-top: -4px;
      }
    }
  }
} 

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
  background: #ac95cf !important;
  border: solid thin #fff;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ac95cf !important;
  background: #ac95cf !important;
  border: solid thin #ac95cf;
}

.ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  height: 2px !important;
  left: 0px !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #ac95cf !important;
}

.ant-steps-item {
  padding-left: 0px !important;
}

.ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button'] {
  :hover {
    background: none !important;
    color: #fff !important;
  }
  .ant-steps-item-icon .ant-steps-icon {
    background: #353535;
    border: solid thin #555;
  }
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #555 !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #555 !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: transparent !important;
}

.ant-steps-vertical {
  flex-direction: row !important;
  width: 100% !important;
  flex-wrap: wrap;
  .ant-steps-item {
    width: 8vw !important; //this one
    flex-grow: 0 !important;
  }
}

.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  display: none;
}

.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  display: block !important;
}

.ant-steps-item:last-child {
  padding-right: 2px;

  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
    display: none !important;
  }
}

.ant-tag-checkable {
  border-radius: 70px !important;
  background-color: rgba(255, 255, 255, 0.11) !important;
  padding: 10px 16px !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 400;
  display: flex !important;
  align-items: center;
  > svg {
    margin-right: 5px;
  }
}

.ant-tag-checkable-checked {
  background-color: #fff !important;
  color: #000 !important;
}

body {
  background-color: #000 !important;
}