@import '../../../styles/variables';
@import '../../../styles/mixin';

.ant-modal.permission-modal .ant-modal-content {
  background-color: #292929;
  background: #292929;
}

.permission-modal-body {
  h1, p, span {
    color: #fff;
    margin: 0;
  }
  .description {
    text-align: center;
    h1 {
      padding: 0px 14px;
    }
    p {
      opacity: 0.5;
    }
    .heading-mobile {
      line-height: normal;
      margin-bottom: 20px;
      padding: 0px 0px;
    }
  }
  .permission-tabs {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.tab {
  &.border {
    padding: 0px 30px;
    margin: 0px 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    @include tablet {
      padding: 0px 12px;
      margin: 0px 12px;
    }
  }
  .tab-description {
    .tab-number {
      padding: 6px 12px;
      margin-right: 8px;
      border-radius: 70%;
      background-color: rgba(255, 255, 255, 0.2);
      span {
        font-size: 14px;
      }
      &-carousel {
        padding: 4px 12px;
        margin-right: 8px;
        border-radius: 70%;
        background-color: rgba(255, 255, 255, 0.2);
        span {
          font-size: 16px;
        }
      }
    }
    .tab-text {
      font-size: 16px;
      &-carousel {
        white-space: pre-wrap;
        width: 90%;
        font-size: 18px;
      }
    }
  }
  .tab-image {
    img {
      width: 100%;
    }
    &-carousel {
      margin-bottom: 0.5rem;
      img {
        border-radius: 12px;
        height: auto;
        width: 50%;
      }
    }
  }
}
