@import '../../../styles/_variables.scss';
@import 'styles/_mixin.scss';

.verify-otp {
  .re-send-message {
    letter-spacing: -0.225px;
    color: rgba(255, 255, 255, 0.7);
  }

  button {
    width: 150px;
    border-radius: 25px;
    @include tablet {
      width: 150px;
      border-radius: 25px;
    }
  }

  .otp-login-success {
    color: $danger;
    font-weight: 700;
  }
}
