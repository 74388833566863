.react-datepicker__input-container {
  input {
    background: rgba(255, 255, 255, 0.12);
    color: white;
    outline: none;
    border: none;
    &::placeholder {
      font-size: 16px;
      opacity: 0.8;
    }
  }
}

.react-datepicker {
  background: #fff !important;
  border: none !important;
}

.react-datepicker__header {
  background: inherit !important;
  .separator {
    width: 0.5px;
    height: 90%;
    background: #000;
    opacity: 0.4;
  }
  .month-caret {
    svg {
      opacity: 0.7;
    }
  }
  .years-select,
  .months-select {
    border: none;
    outline: none;
    padding: 4px 8px;
    appearance: none;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    &:hover {
      background: rgba(0, 0, 0, 0.08);
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(216, 215, 215);
    }
  }
  .years-select {
    margin-right: 4px;
    width: 70px;
  }
  .months-select {
    width: 101px;
  }
}

.react-datepicker__day--selected {
  background: #ac95cf !important;
}

.react-datepicker__day--keyboard-selected {
  background: rgba(172, 149, 207, 0.2) !important;
}
