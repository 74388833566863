@import url('https://assets.unberry.com/Messina+Sansfont/stylesheet.css');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap');

* {
  font-family: 'Messina Sans', Fallback, sans-serif;
}

.hero-section * {
  font-family: 'IBM Plex Sans';
}
