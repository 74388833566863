@import 'styles/_mixin.scss';

.phone-input {
  .ant-form-item {
    .ant-input {
      padding-left: 130px !important;
      @include tablet {
        padding-left: 110px !important;
      }
    }
  }
  &.constraint-width {
    width: 70%;
  }
  @include tablet {
    width: unset;
  }
}

.flag-country-code-container {
  position: absolute;
  width: 90px;
  left: 0px;
  top: -1px;
  z-index: 2;

  &.signup-form {
    top: -4px;
    @include tablet {
      top: -6px;
    }
  }

  .country-code-select {
    opacity: 0;
    position: absolute; 
    left: 0; 
    top: 0;
    background-color: aqua;
  }

  .ant-select {
    z-index: 3;
    cursor: pointer;
    .ant-select-selection-search {
      cursor: pointer;
    }
  }

  .flag-phone-ext {
    position: absolute;
    left: 20px;
    top: 8px;
    height: 50px;
    width: 100%;
    @include tablet {
      left: 10px;
      top: 10px;
      width: 90%;
    }
    
    span {
      font-size: 16px;
      position: relative;
      right: 6px;
      font-weight: 500;
      overflow: hidden;
      @include tablet {
        right: 8px;
        width: 50%;
      }
    }
  }
}

.flag-search {
  .flag-search-input {
    padding-left: 24px;
    padding-bottom: 12px;
    margin: 8px 20px;
    width: 100%;
    background: inherit;
    border: unset;
    border-image: unset;
    border-bottom: 2px solid #dfdfdf;
    color: #bbbbbb;
    font-size: 18px;
    font-family: IBM Plex Sans;
    &:focus-visible {
      outline: unset;
    }
    &::placeholder,
    &::-webkit-input-placeholder  {
      position: relative;
      top: 4px;
      color: #bbbbbb;
      font-size: 18px;
      font-family: IBM Plex Sans;
    }
    caret-color: #FFF;
  }
  .flag-search-icon {
    position: absolute;
    top: 16px;
    left: 12px;
    font-size: 24px;
    opacity: 0.9;
    color: #FFF;
  }
}

.flag-container {
  border-radius: 20px;
  overflow: hidden;
  width: 24px; 
  height: 24px;
  @include tablet {
    width: 20px; 
    height: 20px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.country-code-dropdown {
  background: #3A3A3A !important;
  border-radius: 16px !important;
  .rc-virtual-list-holder {
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      &:hover {
        background-color: #555;
      }
    }
  }
  
  max-width: 300px !important;
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #555555 !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #5a5a5a !important;
  }
  .ant-select-item-option-content {
    display: flex;
    align-items: center;

    span {
      margin-left: 12px;
      color: #FFF;

      &:first-of-type {
        color: #FFF;
        font-weight: 500;
        margin-left: 12px;
      }
      &:last-of-type {
        color: #FFF;
        opacity: 0.9;
        font-weight: 100;
        letter-spacing: 0.1px;
        font-size: 18px;
        margin-left: 12px;
        font-family: IBM Plex Sans;
      }
      
    }
  }
}

.dropdown-arrow {
  position: relative;
  left: 10px;
  display: inline-block;
  cursor: pointer;

  @include tablet {
    left: 12px;
  }

  .arrow {
    position: absolute;
    right: 1px;
    top: -6px;
    width: 10px;
    height: 10px;
    border: solid #999;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    cursor: pointer;

    @include tablet {
      top: -7px;
    }
  }
}

.country-code-separator {
  position: relative;
  left: 22px;
  height: 32px;
  border-left: 3px solid #FFF;
  opacity: 0.6;
  @include tablet {
    left: 21px;
    height: 25px;
  }
}


